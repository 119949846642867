<template>
  <esmp-select disabled :placeholder="name" />
</template>

<script>
export default {
  name: 'ResourceFieldView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    name() {
      return this.settings.find((el) => el.techName === 'name').value || 'Ресурс';
    },
  },
};
</script>
